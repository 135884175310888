import React from 'react'

function GtmScript() {
  const gtmScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5FXLM844')
  `
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: gtmScript
        }}></script>
      {/* <!-- Google tag (gtag.js) --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-5KSV35Q65J"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-5KSV35Q65J');`
        }}
      />
      <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
    </>
  )
}

export default GtmScript
